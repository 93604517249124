body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#body {
  min-height: 400px;
  margin-bottom: 250px;
  clear: both;
}

.landingPage {
  padding: 5vw 15vw 5vw 15vw;
}

@media only screen and (max-width: 600px) {
  .landingPage {
    padding: 5vw 1vw 5vw 1vw;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.landingImage {
  max-width: 100%;
}

#centerButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 600px) {
  #footer {
    display: none;
  }
}