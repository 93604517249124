#body {
  min-height: 400px;
  margin-bottom: 250px;
  clear: both;
}

.registration-form {
  padding: 5vw 20vw 5vw 20vw;
}

@media only screen and (max-width: 600px) {
  .registration-form {
    padding: 5vw 5vw 5vw 5vw;
  }
}

.center-horizontally {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.company-modal {
  max-height: 70vh;
}

.class-selector {
  width: 100%;
  color: red !important;
}

.dropdown-toggle {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dropdown-menu {
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow: auto;
  white-space: nowrap;
}

.dropdown-item {
  width: 100%;
  overflow: auto;
  white-space: nowrap;
  text-align: justify;
}

.required {
  color: red;
  margin-right: 15px;
  font-weight: bold;
}

.not-required {
  margin-left: 21px;
}

#centerButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
}